<template>
   <div class="cart_item">
      <img :src="product.file || product.cover_image?.file" loading="lazy" width="55" sizes="(max-width: 991px) 55px, 133px"
         alt="" class="cart_item-img">
      <div class="cart_item_inner">
         <div class="cart_item-title">"{{ product.title }}"</div>
         <div class="shop_item-info-line">
            <div> <span v-for="material in product.materials" :key="material.id">{{ material.title }}</span></div>
            <div class="shop_item-info-divider"></div>
            <div>{{ product.dimensions }} {{ $tr('cm') }} </div>
         </div>
         <div class="cart_item-price-wrapper">
            <div class="cart_item-price">{{ product.price }} {{ $tr('GEL') }} </div>
            <div v-if="product.old_price" class="cart_item-price is-old">{{ product.old_price }} {{ $tr('GEL') }} </div>
            <div class="cart_item-delete" @click.prevent="onRemove(product.id)">
               <div class="cart_item-delete-text">{{ $tr('remove') }}</div>
            </div>
         </div>
      </div>
   </div>
</template>
 
<script>
export default {
   name: 'CartItem',
   props: {
      product: {
         type: Object,
         require: true
      }
   },
   methods: {
      onRemove(id) {
         this.$store.dispatch('removeProduct', id)
            .catch((err) => {
               this.$store.commit('setError', err.data.message || 'something went wrong');
               setTimeout(() => this.$store.commit('setError', ''), 6000);
            });
      }
   }
}
</script>

<style lang="scss" scoped></style>