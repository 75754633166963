<template>
  <div class="home">
      <Grid  :data="slider" />
  </div>
</template>

<script>
import Grid from "@/components/Grid.vue";
import { mapActions, mapGetters } from "vuex";
import { white } from "@/helperes/variable";
import MouseChanger from "@/components/MouseChanger";

export default {
  name: "HomeView",
  components: {
    MouseChanger,
    Grid
  },
  computed: {
    ...mapGetters({
      slider: "sliderContent/slider"
    })
  },
  methods: {
    ...mapActions({
      getSlider: "sliderContent/getSlider",
      setHeaderBg: "header/setHeaderBg"
    })
  },
  created() {
    this.getSlider();
    this.setHeaderBg(white);
  }
};
</script>

<style lang="scss" scoped>
.home {
  background: #F3F3F1;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100vh;
  overflow: hidden;
}
</style>
