<template>
  <div class="child-container">
    <div @click="close" class="child-header">
      <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.5" d="M7.08373 1.83203L1.91406 7.0017L7.08373 12.1714" stroke="#F3F3F1" stroke-width="2"
              stroke-linecap="round"/>
      </svg>
      <span>{{ $tr('back') }}</span>
    </div>
    <div class="header-title">{{ activeItem.pageTitle }}</div>
    <div class="item"
         v-for="(item,index) in activeChild"
         @mouseover="mouseOver(index)"
         @click="changeRoute(item)"
         :class="{geo:isGeo}"
    >
      <h1 style="font-weight: 300">{{ item.title }}</h1>
    </div>
    <div class="swipers-container">
      <div class="left-swiper swiper-pl-wrapper">
        <div class="swiper-container left-swiper-wrapper">
          <div class="swiper-wrapper ">
            <div v-for="item in swiperImagesOdd" class="swiper-slide">
              <img :src="item" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="right-swiper swiper-pl-wrapper">
        <div class="swiper-container  right-swiper-wrapper">
          <div class="swiper-wrapper">
            <div v-for="item in swiperImagesEven" class="swiper-slide">
              <img :src="item" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css";
import { mapGetters } from "vuex";

export default {
  props: {
    activeItem: [Array, Object],
    activeChild: [Array, Object]
  },
  data() {
    return {
      swiperRight: null,
      swiperLeft: null
    };
  },
  computed: {
    ...mapGetters({
      lang: "lang/activeLang"
    }),
    isGeo() {
      return this.lang === "ka";
    },
    swiperImagesEven() {
      return this.activeChild?.map(item => {
        return item?.content?.images?.[0]?.file;
      });
    },
    swiperImagesOdd() {
      return this.activeChild?.map(item => item?.content?.images?.[1]?.file);
    }
  },
  methods: {
    close() {
      this.$emit("close");
      this.resetSwiperPos();
    },
    mouseOver(index) {
      this.swiperRight.slideTo(index, 1000);
      this.swiperLeft.slideTo(this.swiperImagesOdd.length - 1 - index, 1000);
    },
    resetSwiperPos() {
      this.swiperRight.slideTo(this.swiperImagesEven.length - 1, 0);
      this.swiperLeft.slideTo(0, 0);
    },
    setSwiper() {
      let swiperOptions = {
        direction: "vertical",
        slidesPerView: 1.5,
        spaceBetween: 30
      };
      this.$nextTick(() => {
        setTimeout(() => {
          this.swiperRight = new Swiper(".right-swiper-wrapper", swiperOptions);
          this.swiperRight.slideTo(this.swiperImagesEven.length - 1, 0);

          this.swiperLeft = new Swiper(".left-swiper-wrapper", swiperOptions);
          this.swiperLeft.slideTo(this.swiperImagesOdd.length - 1, 1200);
          this.swiperRight.slideTo(0, 1200);
        }, 1);
      });
    },
    setWidth() {
      let firstSwiper = document.querySelector(".left-swiper");
      let secondSwiper = document.querySelector(".right-swiper");
      let swiperContainer = document.querySelector(".swipers-container");
      let verticalItems = document.querySelectorAll("#vertical-grid-container .grid-vertical-item");
      let gap = verticalItems?.[1]?.getBoundingClientRect()?.left - verticalItems?.[0]?.getBoundingClientRect()?.left;

      firstSwiper.style.width = `${ gap * 4 }px`;
      secondSwiper.style.width = `${ gap * 3 }px`;
      swiperContainer.style.gridGap = `${ gap }px`;
    },
    resizeHandler() {
      this.resizeTimeOut = setTimeout(() => {
        this.setWidth();
      }, 800);
    },
    changeRoute(item) {
      this.$router.push({
        path: `/${ this.lang }/${ item.url }`
      }).then(() => {
        this.close();
        this.$emit("closeMenu");
      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setWidth();
    });
    window.addEventListener("resize", this.resizeHandler);
  },
  watch: {
    activeChild() {
      this.setSwiper();
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeHandler);
  }
};
</script>

<style lang="scss" scoped>
$ease: cubic-bezier(.3, -0.01, 0, .99);
$time: .8s;

.child-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  pointer-events: none;
  left: 50%;
  top: 50%;
  height: 100%;
  width: 100%;
  transform: translate(-50%, -50%) scale(.8);
  transition: all $time $ease;
  opacity: 0;

  @media (max-width: 1023px) {
    h1 {
      font-size: 21px;
    }
  }

  &.active {
    opacity: 1;
    pointer-events: auto;
    transform: translate(-50%, -50%) scale(1);
    transition: all $time .4s $ease;

    .item {
      transition: all $time $ease;
      opacity: 1;
      letter-spacing: 0.25em;

      &.geo {
        letter-spacing: .1em;
      }

      @for $i from 3 through 9 {
        &:nth-child(#{$i}) {
          transition: all $time .4+$i/20+s $ease;
        }
      }
    }
  }

  .swipers-container {
    position: absolute;
    width: 100%;
    height: calc(100vh - 89px);
    top: 89px;
    //overflow: hidden;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 160px;
    pointer-events: none;
  }

  .item {
    position: relative;
    font-weight: 300;
    font-size: 55px;
    line-height: 71px;
    letter-spacing: 0.1em;
    opacity: 0;
    text-transform: uppercase;
    color: white;
    text-align: center;
    cursor: pointer;
    z-index: 2;

    &.geo {
      h1 {
        &:hover {
          letter-spacing: .2em;

        }
      }
    }

    h1 {
      transition: all 0.7s cubic-bezier(0, 0.12, 0.27, 1.01);

      &:hover {
        letter-spacing: .3em;

        &:after {
          transform: scaleX(1) translateX(-50%);
        }
      }

      &:after {
        position: absolute;
        bottom: 0;
        left: 50%;
        content: '';
        height: 2px;
        width: 88px;
        background: white;
        transform: scaleX(0) translateX(-50%);
        transition: all 0.7s cubic-bezier(0, 0.12, 0.27, 1.01);
        transform-origin: left;
      }
    }
  }

  .item {
    font-size: 34px;
    line-height: 44px;
    margin-bottom: 8px;
  }

  .header-title {
    font-weight: 300;
    font-size: 55px;
    line-height: 71px;
    text-align: center;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    margin-top: 10px;
    color: white;
  }

  .child-header {
    cursor: pointer;

    span {
      color: white;
      margin-left: 10px;
    }
  }

  .right-swiper, .left-swiper {
    position: relative;
    height: 100%;
  }

  .right-swiper {
    width: 480px;
  }

  .left-swiper {
    width: 640px;
  }

  .swiper-pl-wrapper {
    transition: all 1s $ease;
  }

  .swiper-container {
    height: 100%;

    .swiper-wrapper {
      transition-timing-function: $ease;
    }

    .swiper-slide {
      opacity: .3;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}


</style>