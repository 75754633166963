import Vue from "vue";
let $vm = Vue.prototype;

export default {
   state: () => ({
      is_open: false,
      cart: [],
      shipping_price: 0,
      total_price: 0,
      error: ''
   }),
   mutations: {
      toggleCartModal(state) {
         state.is_open = !state.is_open;
      },
      addToCart(state, product) {
         if (!state.cart.find(({ id }) => id === product.id)) state.cart.push(product);
         localStorage.setItem('cart', JSON.stringify(state.cart));
      },
      removeFromCart(state, product_id) {
         state.cart = state.cart.filter(({ id }) => id !== product_id);
         localStorage.setItem('cart', JSON.stringify(state.cart));
      },
      updateCart(state, products) {
         state.cart = products;
      },
      setError(state, error) {
         state.error = error || '';
      }
   },
   getters: {
      productIsAdded: (state) => (product_id) => {
         if (state.cart.find(({ id }) => id === product_id)) return true
         return false
      }
   },
   actions: {
      resetCart({ commit }) {
         commit('updateCart', []);
         localStorage.removeItem('cart');
      },
      initCart({ commit, dispatch, rootState }) {
         if (rootState.auth.token) {
            dispatch('getCart');
         } else {
            const cart = JSON.parse(localStorage.getItem("cart")) || false;
            cart ? commit('updateCart', cart) : '';
         }
      },
      addProduct({ rootState, dispatch, commit }, product) {
         return new Promise((resolve, reject) => {
            if (rootState.auth.token) {
               dispatch('addProductTocard', product.id)
                  .then((response) => resolve(response))
                  .catch((err) => {
                     if(err.status === 401){
                        commit('addToCart', product);
                        resolve();
                     }else{
                        reject(err)
                     }
                  })
            }
            else {
               commit('addToCart', product);
               resolve();
            }
         })
      },
      removeProduct({ rootState, dispatch, commit }, id) {
         return new Promise((resolve, reject) => {
            if (rootState.auth.token) {
               dispatch('deleteProductFromCard', id)
                  .then((response) => resolve(response))
                  .catch((err) => reject(err))
            }
            else {
               commit('removeFromCart', id);
               resolve();
            }
         })
      },
      getCart({ rootState, commit }) {
         return new Promise((resolve, reject) => {
            $vm.$axios.get(`card/getUserCard/${rootState.lang._activeLang}`)
               .then((response) => {
                  response.data?.products?.data ? commit('updateCart', response.data.products.data) : '';
                  resolve()
               })
               .catch((err) => {
                  reject(err);
               });
            resolve()
         })
      },
      mergeCart({ state, dispatch }) {
         const ids = state.cart.map(({ id }) => id);
         return new Promise((resolve, reject) => {
            $vm.$axios.post('card/mergeProductToCard', { ids })
               .then((response) => {
                  dispatch('getCart').then(() => {
                     localStorage.removeItem('cart');
                  })
                  resolve()
               })
               .catch((err) => {
                  reject(err);
               });
         })
      },
      addProductTocard({ dispatch }, id) {
         return new Promise((resolve, reject) => {
            $vm.$axios.get(`card/addProductToCard/${id}`)
               .then((response) => {
                  dispatch('getCart')
                  resolve(response)
               })
               .catch((err) => {
                  reject(err);
               });
         })
      },
      deleteProductFromCard({ dispatch }, id) {
         return new Promise((resolve, reject) => {
            $vm.$axios.get(`card/deleteProductFromCard/${id}`)
               .then((response) => {
                  dispatch('getCart')
                  resolve(response)
               })
               .catch((err) => {
                  reject(err);
               });
         })
      },
      getShippingPrice({state}, payload) {
         return new Promise((resolve, reject) => {
            $vm.$axios.post(`card/getShippingPrice`, payload)
               .then((response) => {
                  state.shipping_price = Number(response.data.price.delivery_price);
                  state.total_price = Number(response.data.price.products_price);
                  resolve(response)
               })
               .catch((err) => {
                  reject(err);
               });
         })
      },
      createOrder({}, payload) {
         return new Promise((resolve, reject) => {
            $vm.$axios.post(`card/createOrder`, payload)
               .then((response) => {
                  resolve(response)
               })
               .catch((err) => {
                  reject(err);
               });
         })
      }
   },
};