import store from "@/store";

const cache = {};
const tr = function (str) {
   // const string = str ?  str.toLowerCase() : '';
   const string = str;
   if (!cache[string]) {
      const translations = store.state.translations.translations;
      const lang = store.state.lang._activeLang;
      if (!translations) return;
      if (!string) {
         cache[string] = "";
      } else if (!translations[string]) {
         store.dispatch("addKey", string);
         cache[string] = string;
      } else {
         const result = translations[string][lang] ? translations[string][lang] : string;
         cache[string] = result;
      }
   }

   return cache[string];
};

// if (module.hot) {
//    module.hot.decline('./tr.js');
// }


export default tr;
