<template>
  <div>
    <a @click="is_open=true" class="footer_bottom-link">{{ $tr('Terms &amp; Conditions') }} </a>

    <portal to="terms">
      <transition name="popup">
        <div v-if="is_open" class="popup">
          <div class="popup_window">
            <div style="pointer-events: none" class="popup_bg "></div>
            <div>
              <div class="terms_header">
                <h2 class="popup_heading">{{ terms.title }}</h2>
                <div @click="is_open=close" class="popup_close-btn"></div>
              </div>
              <div v-html="terms.content" class="terms_content-wrapper">
              </div>
            </div>
          </div>
        </div>
      </transition>
    </portal>
  </div>
</template>

<script>
import store from "@/store";
import Vue from "vue";

let $vm = Vue.prototype;

export default {
  name: 'Terms',
  data() {
    return {
      terms: '',
      is_open: false
    };
  },
  methods: {
    getTerms() {
      return $vm.$axios.get(`/getTerms/${ store.getters["lang/activeLang"] }`)
          .then(({ data }) => {
            this.terms = data.terms?.[0];
          });
    },
  },
  created() {
    this.getTerms();
  }
};
</script>

<style lang="scss" scoped>
.cart_items-wrapper::-webkit-scrollbar {
  width: 6px;
}

.footer_bottom-link {
  cursor: pointer;
}

.cart_items-wrapper::-webkit-scrollbar-track {
  background: #BCC1C2;
  margin-bottom: 34px;
  border: solid 2px white;
}

.cart_items-wrapper::-webkit-scrollbar-thumb {
  background: #FEAC58;
  border-radius: 1px;
}

.w-form-fail {
  width: 100%;
}


</style>