<template>
   <div :class="checkout ? 'checkout_form-wrapper' : ''">
      <transition name="slide" mode="out-in">
         <div v-if="step === 1" class="form_block w-form" key="first">
            <form id="wf-form-Forgot-password-form" name="wf-form-Forgot-password-form" data-name="Forgot password form"
               class="form" @submit.prevent>
               <h2 class="form_heading">{{$tr('Forgot password?')}}</h2>
               <p>{{$tr('Enter your email to reset your password.')}}</p>
               <div class="form-fields">
                  <input type="text" class="text-field w-node-_0f052bb4-1499-fd44-cc27-24c3d6d257a0-aa1dcf65 w-input"
                     maxlength="256" name="Email-or-Phone" data-name="Email or Phone" :placeholder="$tr('Email')"
                     id="Email-or-Phone" v-model="email">
               </div>
               <input type="submit" :value="$tr('Send')" data-wait="Please wait..." class="btn w-button" @click.prevent="onSend" :disabled="button_is_disable">
            </form>
            <div class="form_success w-form-done"></div>
            <transition name="fade-up">
               <div v-if="error" class="w-form-fail block">
                  <div v-for="err in error_messages" :key="err">{{$tr(err)}}</div>
               </div>
            </transition>
         </div>
         <div v-else-if="step === 2" class="form_block w-form" key="second">
            <form id="wf-form-Password-code-form" name="wf-form-Password-code-form" data-name="Password code form"
               class="form" @submit.prevent>
               <h2 class="form_heading">{{$tr('Code has been sent to your Email')}}</h2>
               <p>{{$tr('Check out your Email')}}</p>
               <div class="form-fields">
                  <input type="text" class="text-field w-node-_01fa94b1-1db0-1f14-c856-3c6ff9c1d334-aa1dcf65 w-input"
                     maxlength="256" name="Passcode" data-name="Passcode" :placeholder="$tr('Type 8 digit passcode')" id="Passcode"
                     v-model="code">
               </div>
               <div class="form_bottom-line">
                  <span class="form_forgot-password pointer" @click.prevent="onResend">{{$tr('Resend code')}}</span>
               </div>
               <input type="submit" value="Next" data-wait="Please wait..." class="btn w-button"
                  @click.prevent="onCheckCode" :disabled="button_is_disable">
            </form>
            <div class="form_success w-form-done"></div>
            <transition name="fade-up">
               <div v-if="error" class="w-form-fail block">
                  <div v-for="err in error_messages" :key="err">{{$tr(err)}}</div>
               </div>
            </transition>
         </div>
         <div v-else-if="step === 3" class="form_block w-form" key="third">
            <form id="wf-form-New-password-form" name="wf-form-New-password-form" data-name="New password form"
               class="form" @submit.prevent>
               <h2 class="form_heading">{{$tr('New password')}}</h2>
               <div class="form-fields">
                  <input type="password" class="text-field w-node-f3b69fb6-2e68-f968-23f8-4ee1e671a0ad-aa1dcf65 w-input"
                     maxlength="256" name="Password" data-name="Password" :placeholder="$tr('Enter Password')" id="Password"
                     v-model="password">
                  <input type="password" class="text-field w-node-_48bee739-f9ac-3ae8-9b02-8f3ff362dd7c-aa1dcf65 w-input"
                     maxlength="256" name="Password-2" data-name="Password 2" :placeholder="$tr('Repeat Password')" id="Password-2"
                     v-model="password_confirmed">
               </div>
               <div class="form_bottom-line">
                  <p>{{$tr('Description text')}}</p>
               </div>
               <input type="submit" :value="$tr('save')" data-wait="Please wait..." class="btn w-button" @click.prevent="onSubmit" :disabled="button_is_disable">
            </form>
            <transition name="fade">
               <div v-if="success" class="form_success" :class="{ 'padding': checkout }">
                  <div class="form_success-inner">
                     <h2 class="form_success-heading is-no-margin">{{$tr('Your password has been successfully changed!')}}</h2>
                     <img v-if="!checkout" src="@/assets/images/password.webp"
                          alt="Done"  class="form_success-img is-last">
                     <div class="form_success-btn-wrapper">
                        <span class="contact_form-btn pointer" @click="onSuccess">{{$tr('Done')}}</span>
                     </div>
                  </div>
               </div>
            </transition>
            <transition name="fade-up">
               <div v-if="error" class="w-form-fail block">
                  <div v-for="err in error_messages" :key="err">{{ $tr(err) }}</div>
               </div>
            </transition>
         </div>
      </transition>
   </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email, minLength, maxLength, helpers, sameAs } from '@vuelidate/validators'
export default {
   name: 'ForgotPasswordSteps',
   props: {
      checkout: {
         type: Boolean,
         default: false
      }
   },
   setup() {
      return { v$: useVuelidate() }
   },
   data() {
      return {
         email: '',
         code: '',
         password: '',
         password_confirmed: '',
         step: 1,
         success: false,
         error_messages: [],
         error: false,
         button_is_disable: false
      }
   },
   methods: {
      check() {
         return new Promise((resolve, reject) => {
            if (!this.v$.$silentErrors.length) resolve()
            else {
               switch (this.step) {
                  case 1:
                     if (this.v$.email.$invalid) {
                        this.v$.email.$silentErrors.forEach((err) => this.error_messages.push(err.$message));
                        this.error = true;
                     }else{
                        resolve();
                     }
                     break;
                  case 2:
                     if (this.v$.code.$invalid) {
                        this.v$.code.$silentErrors.forEach((err) => this.error_messages.push(err.$message));
                        this.error = true;
                     }else{
                        resolve();
                     }
                     break;
                  case 3:
                     if (this.v$.password.$invalid || this.v$.password_confirmed.$invalid) {
                        this.v$.password.$silentErrors.forEach((err) => this.error_messages.push(err.$message));
                        this.v$.password_confirmed.$silentErrors.forEach((err) => this.error_messages.push(err.$message));
                        this.error = true;
                     }else{
                        resolve();
                     }
                     break;
                  default:
                     resolve();
               }
            }
         })
      },
      async onSend() {
         this.error_messages = [];
         this.error = false;
         await this.check();
         this.button_is_disable = true;
         this.$store.dispatch('getCode', { email: this.email, lang: this.$store.state.lang._activeLang })
            .then(() => {
               this.step = 2;
            })
            .catch((err) => {
               this.error_messages.push(err.data.message);
               this.error = true;
            })
            .finally(() => {
               this.button_is_disable = false;
            })
      },
      async onResend() {
         this.error_messages = [];
         this.error = false;
         await this.check();
         this.button_is_disable = true;
         this.$store.dispatch('getCode', { email: this.email, lang: this.$store.state.lang._activeLang })
            .then(() => {
               this.step = 2;
            })
            .catch((err) => {
               this.error_messages.push(err.data.message);
               this.error = true;
            })
            .finally(() => {
               this.button_is_disable = false;
            })
      },
      async onCheckCode() {
         this.error_messages = [];
         this.error = false;
         await this.check();
         this.button_is_disable = true;
         this.$store.dispatch('verifyCode', { email: this.email, passcode: this.code })
            .then(() => {
               this.step = 3
            })
            .catch((err) => {
               this.error_messages.push(err.data.message);
               this.error = true;
            })
            .finally(() => {
               this.button_is_disable = false;
            })
      },
      async onSubmit() {
         this.error_messages = [];
         this.error = false;
         await this.check();
         this.button_is_disable = true;
         this.$store.dispatch('updatePassword', {
            email: this.email,
            passcode: this.code,
            password: this.password,
            password_confirmed: this.password_confirmed
         })
            .then(() => {
               this.success = true;
               this.$emit('success');
            })
            .catch((err) => {
               this.error_messages.push(err.data.message);
               this.error = true;
            })
            .finally(() => {
               this.button_is_disable = false;
            })
      },
      onSuccess() {
         this.checkout ? this.$emit('onChnageTab', 'login') : this.$emit('onChnageTab'); 
         this.success = false;
      }
   },
   validations() {
      return {
         email: { required: helpers.withMessage('email is requred', required), email: helpers.withMessage('please enter valid email', email) },
         password: {
            required: helpers.withMessage('password is requred', required),
            minLength: helpers.withMessage('password min length is 8', minLength(8))
         },
         password_confirmed: {
            sameAs: helpers.withMessage('please enter same password', sameAs(this.password))
         },
         name: { required: helpers.withMessage('name is requred', required) },
         code: {
            required: helpers.withMessage('code is requred', required),
            minLength: helpers.withMessage('code number length should be 8', minLength(8)),
            maxLength: helpers.withMessage('code number length should be 8', maxLength(8))
         },
      }
   }
}
</script>

<style lang="scss" scoped>
.checkout_form-wrapper {
   position: relative;
}

.form_success {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: #fff;
}

.form_success.padding {
   padding: 20px;
}
</style>