import { green } from "@/helperes/variable";

export default {
  namespaced: true,
  state: () => ({
    showHeader: false,
    headerBackground: null
  }),
  actions: {
    setShowHeader({ commit }, data) {
      commit("SET_SHOW_HEADER", data);
    },
    setHeaderBg({ commit }, data) {
      commit("SET_HEADER_BG", data);
    }
  },
  mutations: {
    SET_SHOW_HEADER(state, data) {
      state.showHeader = data;
    },
    SET_HEADER_BG(state, data) {
      state.headerBackground = data;
    }
  },
  getters: {
    getShowHeader(state) {
      return state.showHeader;
    },
    getHeaderBg(state) {
      return state.headerBackground;
    }
  }
};