import Vue from "vue";
import router from '../../router'
let $vm = Vue.prototype;

export default {
   state: () => ({
      is_open: false,
      user: false,
      token: false
   }),
   mutations: {
      toggleAuthModal(state) {
         state.is_open = !state.is_open;
      },
      setUser(state, user) {
         state.user = user
      },
      setToken(state, token) {
         state.token = token
      }
   },
   getters: {
   },
   actions: {
      resetUser({ commit }) {
         localStorage.removeItem('user')
         localStorage.removeItem('token')
         commit('setUser', false);
         commit('setToken', false); 
      },
      setStorage({ }, { user, token }) {
         user ? localStorage.setItem('user', JSON.stringify(user)) : '';
         token ? localStorage.setItem('token', token) : '';
      },
      initUser({ commit }) {
         const token = localStorage.getItem("token") || false;
         const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : false;
         if (!token) {
            commit('setUser', false)
            commit('setToken', false)
         } else {
            commit('setUser', user)
            commit('setToken', token)
         }
      },
      login({ dispatch, commit }, user) {
         return new Promise((resolve, reject) => {
            $vm.$axios.post('login', user)
               .then((response) => {
                  dispatch('setStorage', { user: response.data.user, token: response.data.authorization.token });
                  dispatch('getUser');
                  dispatch('mergeCart');
                  commit('setUser', response.data.user);
                  commit('setToken', response.data.authorization.token);
                  resolve()
               })
               .catch((err) => {
                  reject(err);
               });
         })
      },
      register({ commit, dispatch }, user) {
         return new Promise((resolve, reject) => {
            $vm.$axios.post('account/registration', user)
               .then((response) => {
                  dispatch('setStorage', { user, token: false });
                  commit('setUser', response.data.user);
                  resolve()
               })
               .catch((err) => {
                  reject(err);
               });
         })

      },
      registerInCheckout({ commit, dispatch }, user) {
         return new Promise((resolve, reject) => {
            $vm.$axios.post('account/registration/checkout', user)
               .then((response) => {
                  dispatch('setStorage', { user: response.data.user, token: response.data.authorization.token });
                  dispatch('getUser');
                  dispatch('mergeCart');
                  commit('setUser', response.data.user);
                  commit('setToken', response.data.authorization.token);
                  resolve()
               })
               .catch((err) => {
                  reject(err);
               });
         })

      },
      logout({ dispatch, rootState }, redirect = false) {
         dispatch('resetUser');
         dispatch('resetCart');
         rootState.cart.shipping_price = 0;
         if(redirect){
            router.push({ path: `/${rootState.lang._activeLang}` })
         }
      },
      getUser({ dispatch, commit, rootState }) {
         return new Promise((resolve, reject) => {
            $vm.$axios.get(`user/profile/${rootState.lang._activeLang}`)
               .then((response) => {
                  dispatch('setStorage', { user: response.data.user, token: false });
                  commit('setUser', response.data.user);
                  resolve(response)
               })
               .catch((err) => {
                  reject(err);
               });
         })
      },
      editUser({ dispatch, commit }, user) {
         return new Promise((resolve, reject) => {
            $vm.$axios.post('user/update', user)
               .then((response) => {
                  dispatch('setStorage', { user: response.data.user, token: false });
                  commit('setUser', response.data.user);
                  resolve(response);
               })
               .catch((err) => {
                  reject(err);
               });
         })
      },
      getCode({ }, user) {
         return new Promise((resolve, reject) => {
            $vm.$axios.post('account/recover', user)
               .then((response) => {
                  resolve(response);
               })
               .catch((err) => {
                  reject(err);
               });
         })
      },
      verifyCode({ }, data) {
         return new Promise((resolve, reject) => {
            $vm.$axios.post('account/codeverify', data)
               .then((response) => {
                  resolve(response);
               })
               .catch((err) => {
                  reject(err);
               });
         })
      },
      updatePassword({ }, data) {
         return new Promise((resolve, reject) => {
            $vm.$axios.post('account/passwordupdate', data)
               .then((response) => {
                  resolve(response);
               })
               .catch((err) => {
                  reject(err);
               });
         })
      }
   },
};