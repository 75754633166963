<template>
  <div
      @mouseenter="onMouseEnter"
      @mouseover="onMouseEnter"
      @mouseleave="onMouseLeave"
      @mouseout="onMouseLeave"
      @mouseup="onMouseLeave"
      class="mouse-changer"
      ref="target"
  >
    <slot/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "MouseChanger",
  props: {
    // show: {
    //     type: Boolean,
    //     trquired: false
    // },
    type: {
      type: String,
      default: "Default"
    },
    mouseLeaveCallback: {
      type: Function,
      required: false
    },
    callBackType: {
      type: String,
      default: "Default"
    }
  },
  methods: {
    ...mapGetters({
      scroll: "scroll/getScroll"
    }),
    ...mapActions({
      setMouseType: "mouseCursor/setMouseType",
      setMouseTarget: "mouseCursor/setMouseTarget"
    }),
    scrollTo() {
      this.scroll.scrollTo(
          document.querySelector(`[data-scroll-id='reputation']`)
      );
    },
    onMouseEnter() {
      this.setMouseType(this.type);
      this.setMouseTarget(this.$refs?.target);
    },
    onMouseLeave() {
      if (typeof this.mouseLeaveCallback === "function") {
        this.mouseLeaveCallback(this.callBackType);
        return;
      }
      this.setMouseType(this.mouseTypes.Default);
      this.setMouseTarget("");
    }
  }
};
</script>
