<template>
  <div :class="{load:load,showHeader:whiteHeader}" class="logo">
    <svg width="16"
         height="30"
         class="first_item"
         viewBox="0 0 16 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.3969 15.2094C15.3969 23.3094 8.89688 29.8094 0.796875 29.8094V0.609375C8.89688 0.609375 15.3969 7.10938 15.3969 15.2094Z"
        fill="#0F0F0F" />
    </svg>
    <svg width="16"
         height="16"
         class="first_circle"
         viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.19844 15.5102C12.2301 15.5102 15.4984 12.2418 15.4984 8.21016C15.4984 4.17848 12.2301 0.910156 8.19844 0.910156C4.16676 0.910156 0.898438 4.17848 0.898438 8.21016C0.898438 12.2418 4.16676 15.5102 8.19844 15.5102Z"
        fill="#0F0F0F" />
    </svg>
    <div class="item-wrapper">
      <svg width="16"
           height="30"
           class="second_item"
           viewBox="0 0 16 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.3969 15.2094C15.3969 23.3094 8.89688 29.8094 0.796875 29.8094V0.609375C8.89688 0.609375 15.3969 7.10938 15.3969 15.2094Z"
          fill="#0F0F0F" />
      </svg>
    </div>
    <svg width="16"
         height="16"
         class="second_circle"
         viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.19844 15.5102C12.2301 15.5102 15.4984 12.2418 15.4984 8.21016C15.4984 4.17848 12.2301 0.910156 8.19844 0.910156C4.16676 0.910156 0.898438 4.17848 0.898438 8.21016C0.898438 12.2418 4.16676 15.5102 8.19844 15.5102Z"
        fill="#0F0F0F" />
    </svg>
    <svg width="67" height="21"
         class="texts"
         viewBox="0 0 67 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.703125 15.5102V0.910156H3.80313C5.30313 0.910156 6.40313 1.01016 7.30313 1.31016C8.10313 1.61016 9.00313 2.11016 9.70312 2.81016C11.2031 4.21016 12.0031 6.01016 12.0031 8.21016C12.0031 10.4102 11.2031 12.3102 9.60313 13.7102C8.80313 14.4102 8.00313 14.9102 7.10313 15.1102C6.30313 15.4102 5.10312 15.5102 3.70312 15.5102H0.703125ZM2.90313 13.4102H3.90313C4.90313 13.4102 5.70313 13.3102 6.40313 13.1102C7.10313 12.9102 7.60312 12.5102 8.20312 12.0102C9.30313 11.0102 9.80313 9.71016 9.80313 8.11016C9.80313 6.51016 9.30313 5.21016 8.20312 4.21016C7.20312 3.31016 5.80313 2.91016 3.90313 2.91016H2.90313V13.4102Z"
        fill="#0F0F0F" />
      <path
        d="M22.7 11.4109H16.1C16.2 12.2109 16.4 12.8109 16.8 13.2109C17.2 13.6109 17.8 13.9109 18.5 13.9109C19 13.9109 19.5 13.8109 19.8 13.5109C20.1 13.2109 20.5 12.8109 21 12.1109L22.8 13.1109C22.5 13.6109 22.2 14.0109 21.9 14.3109C21.6 14.6109 21.3 14.9109 20.9 15.1109C20.5 15.3109 20.2 15.5109 19.8 15.6109C19.4 15.7109 18.9 15.8109 18.5 15.8109C17.1 15.8109 16 15.4109 15.2 14.5109C14.4 13.6109 14 12.4109 14 11.0109C14 9.51094 14.4 8.41094 15.2 7.51094C16 6.61094 17.1 6.21094 18.4 6.21094C19.8 6.21094 20.8 6.61094 21.6 7.51094C22.4 8.41094 22.8 9.51094 22.8 11.1109L22.7 11.4109ZM20.5 9.71094C20.2 8.61094 19.5 8.01094 18.4 8.01094C18.1 8.01094 17.9 8.01094 17.7 8.11094C17.5 8.21094 17.3 8.31094 17.1 8.41094C16.9 8.51094 16.8 8.71094 16.6 8.91094C16.5 9.11094 16.4 9.31094 16.3 9.61094H20.5V9.71094Z"
        fill="#0F0F0F" />
      <path
        d="M30.4062 7.91172L28.6062 8.81172C28.3062 8.21172 28.0062 7.91172 27.6062 7.91172C27.4062 7.91172 27.2062 8.01172 27.1062 8.11172C27.0062 8.21172 26.9062 8.41172 26.9062 8.61172C26.9062 9.01172 27.3062 9.31172 28.1062 9.71172C29.2062 10.2117 30.0062 10.6117 30.4062 11.0117C30.8063 11.4117 31.0062 12.0117 31.0062 12.7117C31.0062 13.6117 30.7062 14.3117 30.0062 14.9117C29.4062 15.5117 28.6062 15.8117 27.7062 15.8117C26.2062 15.8117 25.1062 15.0117 24.4062 13.5117L26.2062 12.7117C26.5062 13.1117 26.6063 13.4117 26.8063 13.5117C27.1063 13.7117 27.4062 13.9117 27.7062 13.9117C28.4062 13.9117 28.8063 13.6117 28.8063 12.9117C28.8063 12.5117 28.5062 12.2117 28.0062 11.8117C27.8062 11.7117 27.6063 11.6117 27.4062 11.5117C27.2062 11.4117 27.0062 11.3117 26.7062 11.2117C26.1062 10.9117 25.6063 10.6117 25.4062 10.3117C25.1062 9.91172 24.9062 9.41172 24.9062 8.81172C24.9062 8.01172 25.2062 7.31172 25.7062 6.81172C26.3062 6.31172 26.9062 6.01172 27.7062 6.01172C29.0062 6.01172 29.9062 6.61172 30.4062 7.91172Z"
        fill="#0F0F0F" />
      <path
        d="M32.7031 2.50937C32.7031 2.10937 32.8031 1.80938 33.1031 1.50938C33.4031 1.20938 33.7031 1.10938 34.1031 1.10938C34.5031 1.10938 34.8031 1.20938 35.1031 1.50938C35.4031 1.80938 35.5031 2.10937 35.5031 2.50937C35.5031 2.90937 35.4031 3.20937 35.1031 3.50937C34.8031 3.80937 34.5031 3.90938 34.1031 3.90938C33.7031 3.90938 33.4031 3.80937 33.1031 3.50937C32.8031 3.20937 32.7031 2.80937 32.7031 2.50937ZM35.2031 6.30937V15.5094H33.1031V6.30937H35.2031Z"
        fill="#0F0F0F" />
      <path
        d="M46.8 15.3094C46.8 15.7094 46.8 16.1094 46.8 16.5094C46.8 16.9094 46.7 17.1094 46.7 17.4094C46.5 18.1094 46.3 18.7094 45.8 19.2094C45 20.2094 43.8 20.6094 42.4 20.6094C41.2 20.6094 40.2 20.3094 39.4 19.6094C38.6 18.9094 38.1 18.0094 38 16.8094H40.1C40.2 17.3094 40.3 17.6094 40.5 17.9094C40.9 18.5094 41.6 18.8094 42.4 18.8094C44 18.8094 44.7 17.8094 44.7 15.9094V14.6094C43.9 15.5094 42.9 15.9094 41.8 15.9094C40.5 15.9094 39.5 15.4094 38.7 14.5094C37.9 13.6094 37.5 12.4094 37.5 11.0094C37.5 9.70937 37.9 8.50937 38.6 7.60938C39.4 6.60938 40.5 6.10938 41.8 6.10938C43 6.10938 43.9 6.50938 44.7 7.40938V6.40938H46.8V15.3094ZM44.8 10.9094C44.8 10.0094 44.6 9.30937 44.1 8.80937C43.6 8.30937 43 8.00937 42.2 8.00937C41.4 8.00937 40.8 8.30938 40.3 8.90938C39.9 9.40938 39.7 10.1094 39.7 11.0094C39.7 11.8094 39.9 12.5094 40.3 13.1094C40.8 13.7094 41.4 14.0094 42.2 14.0094C43 14.0094 43.7 13.7094 44.1 13.1094C44.6 12.4094 44.8 11.8094 44.8 10.9094Z"
        fill="#0F0F0F" />
      <path
        d="M49.6016 6.30937H51.7016V7.20938C52.4016 6.50938 53.3016 6.10938 54.2016 6.10938C55.3016 6.10938 56.1016 6.40938 56.7016 7.10938C57.2016 7.70938 57.5016 8.60937 57.5016 9.90937V15.6094H55.4016V10.4094C55.4016 9.50937 55.3016 8.90937 55.0016 8.50937C54.8016 8.10937 54.3016 8.00937 53.7016 8.00937C53.0016 8.00937 52.5016 8.20938 52.2016 8.70938C51.9016 9.20938 51.8016 10.0094 51.8016 11.1094V15.6094H49.7016V6.30937H49.6016Z"
        fill="#0F0F0F" />
      <path
        d="M65.5 7.91172L63.7 8.81172C63.4 8.21172 63.1 7.91172 62.7 7.91172C62.5 7.91172 62.3 8.01172 62.2 8.11172C62.1 8.21172 62 8.41172 62 8.61172C62 9.01172 62.4 9.31172 63.2 9.71172C64.3 10.2117 65.1 10.6117 65.5 11.0117C65.9 11.4117 66.1 12.0117 66.1 12.7117C66.1 13.6117 65.8 14.3117 65.1 14.9117C64.5 15.5117 63.7 15.8117 62.8 15.8117C61.3 15.8117 60.2 15.0117 59.5 13.5117L61.3 12.7117C61.6 13.1117 61.7 13.4117 61.9 13.5117C62.2 13.7117 62.5 13.9117 62.8 13.9117C63.5 13.9117 63.9 13.6117 63.9 12.9117C63.9 12.5117 63.6 12.2117 63.1 11.8117C62.9 11.7117 62.7 11.6117 62.5 11.5117C62.3 11.4117 62.1 11.3117 61.8 11.2117C61.2 10.9117 60.7 10.6117 60.5 10.3117C60.2 9.91172 60 9.41172 60 8.81172C60 8.01172 60.3 7.31172 60.8 6.81172C61.4 6.31172 62 6.01172 62.8 6.01172C64.1 6.01172 64.9 6.61172 65.5 7.91172Z"
        fill="#0F0F0F" />
    </svg>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      load: false,
      titleTimeout: null
    };
  },
  computed: {
    ...mapGetters({
      showHeader: "header/getShowHeader",
      headerBg: "header/getHeaderBg"
    }),
    whiteHeader() {
      return this.showHeader || this.headerBg === "#0F0F0F";
    }
  },
  mounted() {
    setTimeout(() => {
      this.load = true;
    }, 0);
    this.titleTimeout = setTimeout(() => {
      document.querySelector(".item-wrapper").style.background = "transparent";
    }, 1200);
  },
  beforeDestroy() {
    if (this.titleTimeout) clearTimeout(this.titleTimeout);
  }
};
</script>

<style lang="scss" scoped>
$ease: cubic-bezier(.3, -0.01, 0, .99);
$time: .8s;

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 7px;

  @media screen and (max-width: 767px) {
    grid-gap: 3px;
    width: 100px;
  }

  &.load {
    .first_item {
      transform: scale(1) rotate(0);
    }

    .first_circle, .texts {
      transform: scale(1);
    }

    .second_item {
      transform: translateX(0);
    }

    .second_circle {
      transform: scale(1) translateX(0);
    }
  }

  &.showHeader {
    path {
      fill: white !important;
    }

    .item-wrapper {
      background: transparent;
      transition: .01ms;
    }
  }

  .first_item {
    transform: scale(.2) rotate(180deg);
    transition: transform $time $ease;
  }

  .first_circle {
    transform: scale(0);
    transition: transform $time .2s $ease;
  }

  .second_item {
    transform: translateX(-100%);
    transition: transform $time .3s $ease;
  }

  .second_circle {
    transform: scale(.7) translateX(-34px);
    transition: transform $time .6s $ease;
  }

  .item-wrapper {
    overflow: hidden;
    display: flex;
    align-items: center;
    height: fit-content;
    position: relative;
    z-index: 2;
    //background: white;
    transition: .1ms .2s ease;
  }

  .texts {
    margin-top: 4px;
    transform: scale(0);
    transition: transform $time .7s $ease;
  }
}
</style>